<template>
  <div class="update-entity">
    <div class="edit-page__header" v-if="isReady">
      <div class="wrapper_edit_forms content d-flex justify-content-between">
        <div class="zq-create-page-title-wrapper">
          <h3 class="zq-child-title-text zq-edit-title">Edit {{entityName}}</h3>
        </div>
        <CButton
          :color="theme === 'default' ? 'secondary' : ''"
          type="button"
          class="mr-3 zq--custom-button mb-1"
          @click="$router.go(-1)"
        >
          {{ $t('buttons.goBack') }}
        </CButton>
      </div>
      <div class="tabs_wrapper">
        <div
          @click="showTab('settings')"
          :class="[{active: tabName === 'settings', notSaved:  !isSettingsDataSaved}, tabClasses]"
        >
          Settings
          <div class="edit-tooltip-wrapper" v-if="!isSettingsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div
              class="tooltip-old bs-tooltip-old-auto fade show"
              role="tooltip"
              aria-hidden="true"
              x-placement="right"
            >
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-content content position-relative">
      <div v-if="tabName === 'settings' && isReady">
        <CCardHeader>
          <div class="mb-4 d-flex justify-content-between align-items-center">
            <strong class="title">Edit Settings</strong>
            <div class="message" v-if="!isSettingsDataSaved">Data not saved!</div>
            <div class="mb-4 d-flex justify-content-between align-items-center">
              <CButton
                class="action-create-button zq--responsive-button__common"
                type="submit"
                @click="updateSettings"
              >
                Update Settings
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="settingsCollapsed = !settingsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit Settings</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="settingsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="settingsCollapsed" :duration="400">
                <CCardBody class="overflow-hidden">
                  <CForm
                    ref="updateFileObject"
                    v-on:submit.prevent="updateFileObject"
                    novalidate
                    class="update-fileObject-form"
                  >
                    <FormBuilder
                      :list="formList"
                      :isCreateHeader="false"
                      @updated="updateFormData"
                      :page="{
                        title: texts.editPage.title,
                        info: descriptions,
                        mode: 'update',
                      }"
                    />
                    <codemirror v-if="!isImageFile && !isHtmlFile" v-model="fileString" :options="cmOptions" />
                    <img v-if="isImageFile" :src="fileString"  alt="file" />
                  </CForm>
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <PreviewSpiner v-if="!isReady" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEqual } from 'lodash';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import { dateFormate } from '@/utils/dateFormate';
import { formConfig, pageConfig } from "@/config";
import fieldHelpers from '@/utils/ZiqniFieldHelper.js';
import { fileObject } from '@/config/descriptions/fileObject';
import { fileObjectsTexts } from '@/config/pageTexts/fileObjects.json';
import fields from '@/generated/ziqni/store/modules/fileObjects/fields';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import PreviewSpiner from '@/shared/UI/Spiner';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/display/autorefresh.js';
import 'codemirror/addon/fold/brace-fold.js';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/comment-fold';

export default {
  name: 'EditFile',
  components: {
    ClCardArrow,
    PreviewSpiner,
    codemirror,
  },
  data() {
    return {
      model: 'fileObject',
      entityId: this.$route.params.fileId,
      code: 'const A = 10',
      cmOptions: {
        tabSize: 4,
        styleActiveLine: false,
        lineNumbers: true,
        styleSelectedText: false,
        line: true,
        foldGutter: true,
        lineWrapping: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
        hintOptions:{
          completeSingle: false
        },
        matchBrackets: true,
        showCursorWhenSelecting: true,
        extraKeys: { "Ctrl": "autocomplete" }
      },
      entityName: '',
      descriptions: {
        ...fileObject.edit
      },
      texts: {
        ...fileObjectsTexts
      },
      isShowDropdown: false,
      tabName: "settings",
      tabClasses: "btn edit-tab",
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      showCMetaInputs: false,
      showCAdjustmentFactorsInputs: false,
      formData: null,
      accountType: [],
      options: ["Slots", "Casino", "Hockey"],
      idValidate: null,
      nameValidate: null,
      typeValidate: null,
      page: pageConfig.page,
      formList: [],
      translatableFields: [],
      translationsData: {},
      modelFields : {
        ...fields,
      },
      schedulingData: null,
      dependantOnData: null,
      rewardModels: ['achievement'],
      ruleModels: ['achievement'],
      settingsCollapsed: true,
      dependantOnCollapsed: false,
      schedulingCollapsed: false,
      isReady: false,
      isSettingsDataSaved: true,
      isRulesDataSaved: true,
      isRewardsDataSaved: true,
      isTranslationsDataSaved: true,
      notSavedText: 'Data not saved!',
      notSavedRulesData: {},
      notSavedTranslationsData: {},
      notSavedRewardsData: {},
      fileString: '',
      isImageFile: false,
      isHtmlFile: false,
      path: ''
    }
  },
  computed: {
    ...mapGetters('fileRepositories', ['fileRepository']),
    ...mapGetters('files', ['success', 'message', 'fileObjects', 'fileObject']),
    ...mapGetters('actionTypes', ['success', 'message', 'actionType', 'actionTypes']),
    ...mapGetters('theme', ['theme']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
    created() {
      return dateFormate(this.formData.created)
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('files', [
      'handleGetFileObjects_item',
      'handleUpdateFileObjects',
      'handleDownloadFileObjects',
      'handleUploadFileObjects',
      'handleGetFileObjectsByQuery',
    ]),
    ...mapActions('actionTypes', ['handleGetActionTypes_item', 'handleGetActionTypes']),
    ...mapActions('fileRepositories', ['handleGetFileRepositories_item']),
    async initialize() {
      this.handleGetActionTypes([]);
      await this.handleGetFileRepositories_item([this.$route.params.id],1,0);
      await this.handleGetFileObjects_item({idArray: [this.$route.params.fileId]})
        .then(data => {
          let actionTypesIds = []
          if (data[0].actionTypeAdjustmentFactors) {
            actionTypesIds = data[0].actionTypeAdjustmentFactors.map((item) => {
              return item.actionTypeId
            })
          }

          this.query = actionTypesIds.length ? {'id': actionTypesIds.join()} : []; // just query for the ids!

          this.handleGetActionTypes_item(actionTypesIds);
          this.formList = fieldHelpers.prepareEditFormList(
            fields,
            this.texts.editPage,
            this.descriptions,
            this.fileObject
          );

          this.formData = data[0];
          this.isReady = true;
        });
      this.translatableFields = fields.baseFields.translatableFields;

      const fileType = this.fileObject.mimeType.split('/')[0];
      const isHtml = this.fileObject.mimeType.split('/')[1] === 'html';

      if (fileType === 'image') {
        this.fileString = this.fileObject.uri;
        this.isImageFile = true;
      } else if (isHtml) {
        this.isHtmlFile = true;
      } else {
        fetch(this.fileObject.uri)
          .then(res => res.blob())
          .then(async (blob) => {
            this.fileString = await new Response(blob).text();
          });
      }
    },
    showTab(name) {
      this.tabName = name;
    },
    setRulesDataSaved(val) {
      this.isRulesDataSaved = val;
    },
    setRewardsDataSaved(val) {
      this.isRewardsDataSaved = val;
    },
    setTranslationsDataSaved(val) {
      this.isTranslationsDataSaved = val;
    },
    setNotSavedRulesData(obj) {
      this.notSavedRulesData = obj;
    },
    setNotSavedTranslationsData(obj) {
      this.notSavedTranslationsData = obj;
    },
    setNotSavedRewardsData(obj) {
      this.notSavedRewardsData = obj;
    },
    updateFormData(val) {
      if (this.formData && !isEqual(this.formData, val)) {
        this.isSettingsDataSaved = false;
      }
      this.formData = val;
    },
    async updateSettings() {
      delete this.formData.statusCode;
      delete this.formData.status;
      delete this.formData.achievementLiveStatus;
      delete this.formData.spaceName;
      delete this.formData.created;
      delete this.formData.productRefId;
      delete this.formData.rewardType;
      delete this.formData.constraints;
      delete this.formData.memberRefId;
      delete this.formData.key;
      delete this.formData.path;
      delete this.formData.uri;
      delete this.formData.size;

      const body = [this.formData];

      await this.handleUpdateFileObjects({updateFileObjectRequestArray: body})
        .then(() => {
          this.isSettingsDataSaved = true;
        })

      const parts = [
        new Blob([this.fileString], {
          type: this.fileObject.mimeType
        })
      ];

      const file = new File(parts, this.fileObject.name, {
        lastModified: new Date(),
        type: this.fileObject.mimeType
      });

      const payload = {
        filesArray: file,
        parentFolderPath: this.formData.parentFolderPath,
        repositoryId: this.formData.repositoryId,
        tagsArray: 'files',
      };

      await this.handleUploadFileObjects(payload)
    }
  },
  watch: {
    fileObject: {
      deep: true,
      handler: async function(val) {
        this.entityName = val.name

        let folderName = this.fileObject.parentFolderPath.replace('/', '');
        const idx = folderName.indexOf('/');
        if (idx !== -1) {
          const arr = folderName.split('/');
          folderName = arr[arr.length - 1];
        }

        let folderId = '';

        if (folderName) {
          const folder = await this.handleGetFileObjectsByQuery({
            idArray: [],
            queryRequest: {
              must: [
                {
                  queryField: 'repositoryId',
                  queryValues: [this.$router.currentRoute.params.id],
                },
                {
                  queryField: 'mimeType',
                  queryValues: ['inode/directory'],
                },
                {
                  queryField: 'name',
                  queryValues: [folderName],
                },
              ],
            }
          });

          folderId = folder[0].id;
        }

        const innerFolders = this.$router.currentRoute.query.innerFolders.length
          ? [...JSON.parse(this.$router.currentRoute.query.innerFolders).reverse(), { id: folderId, name: folderName }]
          : [];

        routerBreadcrumbs(this.$router.currentRoute, {
          fileId: this.fileObject.id,
          fileName: this.fileObject.name,
          innerFolders: innerFolders,
          repositoryName: this.fileRepository.name,
          id: this.$router.currentRoute.params.id,
        });
      },
    },
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";
.update-entity {
  height: 100%;
  .card-header {
    background-color: #f3f3f3;
    & ~ .row:first-of-type {
      margin-top: 1rem;
    }
  }
  .card {
    &.zq--wizard-card {
      background-color: var(--zq-sub-bg);
    }
  }
  .edit-tooltip-wrapper {
    display: inline-block;
    position: relative;
    .tooltip-old {
      position: absolute;
      visibility: hidden;
      top: -4px;
      left: 16px;
    }
  }
  .multiselect {
    &--disabled {
      .multiselect__single {
        background: none;
      }
    }
  }
  .fa-question-circle-o:hover ~ .tooltip-old {
    visibility: visible !important;
  }
  .message {
    color: var(--zq-warn);
  }
  .notSaved {
    color: var(--zq-warn) !important;
  }
  .form-content {
    border-top: 0;
  }
  .edit-page__header {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--zq-main-bg);
  }
  .wrapper_edit_forms {
    display: flex;
  }
  .edit-tab {
    width: 50%;
    height: 35px;
    cursor: pointer !important;
    border-radius: 12px 12px 0 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #858585;
    text-align: left;
    &.active {
      border-bottom: solid 2px #007dfb;
    }
    &.btn {
      padding: 0.375rem 1rem;
      text-align: center;
    }
  }
  .tabs_wrapper {
    height: 100%;
    max-height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-radius: 12px 12px 0 0;
  }
  & > .form-content {border-top: none}
  .update-entity-form {
    height: calc(100% - 50px);
    input::placeholder {
      font-style: italic;
    }
    .card {
      background-color: var(--zq-sub-bg);
    }
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      .form-control.is-valid {
        padding-right: 15px;
      }
      .custom-multi-select .multiselect__select {
        opacity: 0;
      }
      .adjustment-factors-data {
        .fa-question-circle-o {
          top: 10px;
          right: -20px;
        }
      }
      .search-icon {
        position: absolute;
        width: 30px !important;
        height: 47px !important;
        right: 15px;
        top: 1px;
        padding: 4px 8px;
        text-align: center;
      }
      .editor {
        min-height: 200px;
        height: 200px;
      }
      .fa-question-circle-o {
        position: absolute;
        top: 0;
        right: -10px;
      }
      .meta-data {
        min-width: 70px;
        .fa-question-circle-o {
          top: 10px;
        }
      }
    }
  }
  @include media-breakpoint-up('768px') {
    .edit-page__header {
      padding: 0;
    }
    .wrapper_edit_forms {
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: -8px;
    }
    .tabs_wrapper {
      margin: 0 3rem;
      min-width: 375px;
      width: 47%;
      max-height: 54px;
    }
    .edit-tab {
      height: 54px;
      line-height: 42px;
      cursor: pointer !important;
      &:hover {
        color: #858585;
      }
    }
  }
}
</style>
